<template>
    <div>
        <div class="Allorders">
            <div class="aordertitle">
                全部订单状态
                <img class="top1" src="../images/top1.png" alt="">
            </div>
            <div class="aordercont">
                <div class="bi1">
                    <div id="timeSelector">
                        <div style="margin-bottom: 20px;color: #292E33;font-size: 16px;font-weight: 700;padding-left: 25px;">
                            <img class="dataicon" src="../images/dataicon.png" alt="">
                            选择指定日期</div>
                            <label v-for="range in timeRanges" :key="range.value" class="time-label">
        <input type="radio" :value="range.value" v-model="selectedTimeRange" class="hidden-radio" @change="selectTimeRange(range.value)">
        <span :class="{'selected': selectedTimeRange === range.value}">{{ range.label }}</span>
      </label>
      <div class="date-input-wrapper">
        <input type="date" v-model="chardata.stime" @change="getorderchart" class="date-input">
        <input type="date" v-model="chardata.etime" @change="getorderchart" class="date-input">
      </div>
                    </div>
                    <div id="labelSelector">
                        <label v-for="label in labels" :key="label" class="label-item">
                            <div class="label-content">
                            <div class="jjfather" :style="{ background: getLabelColor(label) }">
                                <img class="jiajian" :src="getIconSrc(label)" alt="">
                            </div>
                            <input type="checkbox" :value="label" v-model="selectedLabels" class="hidden-checkbox">
                            <span class="label-text">{{ label }}</span>
                            </div>
                        </label>
                    </div>
                    <div id="chart" style="width: 70%; height: 300px;"></div>
                </div>
                <div class="bi2">
                    <div class="b2_s">
                        <div class="s_z">
                            <img class="c1" src="../images/c1.png" alt="">
                            <div class="c_right_div">
                                <div class="s_z_text">待受理订单</div>
                                <div class="s_z_num" style="color: #E6A23C;margin-top: 10px;">{{countdata.pending_review}}单</div>
                            </div>
                        </div>
                        <div class="s_y">
                            <img class="c2" src="../images/c2.png" alt="">
                            <div class="c_right_div">
                                <div class="s_z_text">待付款订单</div>
                                <div class="s_z_num" style="color: #FF5858;margin-top: 10px;">{{countdata.pending_payment}}单</div>
                            </div>
                        </div>
                    </div>
                    <div class="b2_x">
                        <div class="x_z">
                            <img class="c3" src="../images/c3.png" alt="">
                            <div class="c_right_div">
                                <div class="s_z_text">索赔成功订单</div>
                                <div class="s_z_num" style="color: #2495ED;margin-top: 10px;">{{countdata.successful_claim}}单</div>
                            </div>
                        </div>
                        <div class="x_y">
                            <img class="c4" src="../images/c4.png" alt="">
                            <div class="c_right_div">
                                <div class="s_z_text">处理中订单</div>
                                <div class="s_z_num" style="color: #88D782;margin-top: 10px;">{{countdata.processing}}单</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import axios from 'axios';
import config from '../lib/config';
import jia from '../images/jia.png';
import jian from '../images/jian.png';
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
        timeRanges: [
        { label: '近一周', value: 'week' },
        { label: '近一月', value: 'month' },
        { label: '近三月', value: 'threeMonths' }
      ],
        all:true,
        examine:true,
        operates:true,
        countdata:{
            pending_review: 0,
            processing: 0,
            pending_payment: 0,
            successful_claim: 0
        },
        chardata:{
            stime:'',
            etime:'',
        },
        labels: ['全部订单', '待处理', '处理中', '待付款', '投诉完成', '索赔成功'],
        selectedLabels: ['全部订单', '待处理', '处理中', '待付款', '投诉完成', '索赔成功'],
        data: {
            '全部订单': 120,
            '待处理': 80,
            '处理中': 100,
            '待付款': 60,
            '投诉完成': 40,
            '索赔成功': 20
        },
        colors: {
        '全部订单': '#7739FB',
        '待处理': '#88D782',
        '处理中': '#E6A23C',
        '待付款': '#F54545',
        '投诉完成': '#A3B9CC',
        '索赔成功': '#282D32'
      }
    }
  },
  mounted() {
        let _this = this; 
        _this.getordercount();
        _this.getorderchart();
        window.addEventListener('resize', this.resizeChart);
	},
  watch: {
    selectedLabels() {
      this.updateChart();
    }
  },
     methods: {
        getLabelColor(label) {
            return this.colors[label];
        },
        getIconSrc(label) {
            return this.selectedLabels.includes(label) ? jian : jia;
        },
        initChart() {
            this.chart = echarts.init(document.getElementById('chart'));
            this.updateChart();
        },
        selectTimeRange(range) {
            this.timeRange = range;
            const now = new Date();
            switch (range) {
                case 'week':
                this.chardata.stime = new Date(now.setDate(now.getDate() - 7)).toISOString().split('T')[0];
                break;
                case 'month':
                this.chardata.stime = new Date(now.setMonth(now.getMonth() - 1)).toISOString().split('T')[0];
                break;
                case 'threeMonths':
                this.chardata.stime = new Date(now.setMonth(now.getMonth() - 3)).toISOString().split('T')[0];
                break;
            }
            this.chardata.etime = new Date().toISOString().split('T')[0];
            this.getorderchart();
        },
        updateChart() {
        let selectedLabels = Array.from(document.querySelectorAll('#labelSelector input:checked'))
        .map(input => input.value);
        const option = {
            tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
            },
            xAxis: {
            type: 'category',
            data: selectedLabels,
            axisLabel: {
                interval: 0,
                rotate: 0
            }
            },
            yAxis: {
            type: 'value'
            },
            series: [{
                name: '数量',
                type: 'bar',
                data: selectedLabels.map(label => ({
                    value: this.data[label] || 0,
                    itemStyle: { color: this.colors[label] },
                    label: {
                      show: true,
                      position: 'top', // 显示在柱子顶部
                      formatter: '{c}' // 显示的内容为数值
                    }
                })),
                emphasis: {
                    itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
                }]
        };
      this.chart.setOption(option);
    },
    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
      getordercount(){
        let _this = this;
        axios.get(config.order_count,{})
        .then(function (response) {
            if(response.data.code == 200){
                _this.countdata = response.data.data;
            }else{
               _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      getorderchart(){
        let _this = this;
        axios.get(config.order_chart,{params:_this.chardata})
        .then(function (response) {
            if(response.data.code == 200){
                let data = response.data.data; 
               _this.data['全部订单'] = data.all;
               _this.data['待处理'] = data.pending_review;
               _this.data['处理中'] = data.processing;
               _this.data['待付款'] = data.pending_payment;
               _this.data['投诉完成'] = data.complaint_completed;
               _this.data['索赔成功'] = data.successful_claim;
               _this.initChart();
                }else{
                    _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeChart);
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
</script>
<style scoped>
#labelSelector label {
  margin-right: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}
.Allorders{
    width: 100%;
    height: 596px;
}
.aordertitle{
    width: 100%;
    box-sizing: border-box;
    position: relative;
    background: #2495ED;
    height: 104px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    font-family: Alibaba PuHuiTi;
    font-size: 24px;
    font-weight: 700;
    line-height: 104px;
    text-align: left;
    color: #FFFFFF;
    padding-left: 30px;
}
.aordercont{
    width: 100%;
    height: 492px;
    border: Mixed solid;
    display: flex;
}
.bi1{
    width: 45%;
    height: 372px;
    margin-left: 4%;
    margin-top:4%;
    background-color: aliceblue;
    border-radius: 15px;
    position: relative;
}
.bi2{
    width: 45%;
    height: 372px;
    margin-left: 2%;
    margin-top:4%;
    border-radius: 15px;
}
.b2_s,.b2_x{
    display: flex;
}
.s_z,.s_y,.x_z,.x_y{
    width: 48%;
    height: 172px;
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
}
.s_z{
    background: #FEF9F1;
    margin-right: 4%;
    margin-bottom: 28px;
}
.s_y{
    background: #FDF4F4;
}
.x_z{
    background: #F3F9FF;
    margin-right: 4%;
}
.x_y{
    background: #F4FBF3;
}
.c1,.c2,.c3,.c4{
    margin: 5px 0px 5px 15px;
}
.c3{
    width: 46%;
    height: 74%;
    margin-top: auto;
    margin-bottom: auto;
}
.c4{
    width: 50%;
    height: 85%;
    margin-top: auto;
    margin-bottom: auto;
}
.c_right_div{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
}
.s_z_text{
    font-family: Alibaba PuHuiTi;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.44px;
    text-align: left;
}
.s_z_num{
    font-family: Alibaba PuHuiTi;
    font-size: 38px;
    font-weight: 700;
    line-height: 52.14px;
    text-align: left;
}
.top1{
    position: absolute;
    width: 205.55px;
    height: 155.2px;
    top: 5px;
    right: 50px;
}

#timeSelector button, #timeSelector input {
  margin-bottom: 10px;
}
#labelSelector{
    margin-top: 30px;
    margin-bottom: 20px;
}
#timeSelector{
    position: absolute;
    left: 72%;
    top:30%;
    display: grid;
    text-align: left;
}
#labelSelector label {
  margin-right: 16px;
  cursor: pointer;
}
#labelSelector input {
  margin-right: 5px;
}
.dataicon{
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
}
.jjfather{
    width: 20px;
    height: 20px;
    border-radius: 100px;
    overflow: hidden;
}
#labelSelector {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-left: 30px;
}

.label-item {
  margin-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.label-content {
  display: flex;
  align-items: center;
}

.jjfather {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.jiajian {
  width: 14px;
}

.hidden-checkbox {
  display: none;
}

.label-text {
  font-size: 14px;
}


.time-label {
  margin-right: 20px;
  cursor: pointer;
}
.date-input-wrapper {
  align-items: center;
}

.date-input {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.date-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

</style>
